//Models 
import HomeModel from './model.js';
import AppModel from '../App/model.js';
import FiltersModel from '../_filters/model.js';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// update home page map
const updateHomePageMap = HomeModel.updateHomePageMap;
// update the query string based on the selected filters
const updateQueryString = FiltersModel.updateQueryString;
// show alert message
const showAlert = AppModel.showAlert;

$(document).ready(function(){
    
    // initialize real estate ads swiper
    new Swiper(".properties-slider", {
        loop: false,
        slidesPerView: 'auto',
        centeredSlides: false,
        modules: [Navigation],
        navigation: {
            nextEl: '.custom-button-next',
            prevEl: '.custom-button-prev',
        },
        on: {
            navigationNext: function () {
                $('#current-slide').html(this.realIndex + 1);
            },
            navigationPrev: function () {
                $('#current-slide').html(this.realIndex + 1);
            },
        },
        breakpoints: {
            400: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 10,
            }
        },
    });

    // validate filters before submitting the form and modify the query string
    $('#search-page-filters').on('submit', function (e) {
        e.preventDefault();
        const $form = $(this);
        const $maxSpace = $('#space_max');
        const $maxPrice = $('#price_max');
    
        // validate the price and space filters
        if (parseInt($maxPrice.val()) < parseInt($('#price_min').val())) {
            showAlert($maxPrice.data('error-msg'), 'error');
            return;
        }
        
        if (parseInt($maxSpace.val()) < parseInt($('#space_min').val())) {
            showAlert($maxSpace.data('error-msg') , 'error');
            return;
        }
        // Redirect to the new URL
        window.location.href = $form.attr('action') + '?' + updateQueryString($form);
    });

    //update home page map when user change the ad type
    $('input[name="ad_type"]').on('change',function(){
        if($('#search-page-filters').hasClass('home')){
            updateHomePageMap();
        }
    });

    $('#homepage-map path , .ads-count').on({
        click: function () {
            const target = $(this);
            var regionName = '';
        
            if(target.hasClass('map-region')) {
                regionName = target.data('region-name');
            }else{
                regionName = target.siblings('.map-region').data('region-name');
            }
        
            if (typeof regionName !== 'undefined' && regionName != '') {
                const location = regionName.toLowerCase();
                const queryString = $('#search-page-filters').data('query');
                const url = `/real-estate/ad/search?${queryString}&locations[]=${location}&sort_by=date_desc&map_view=true`;
                window.location.href = url;
            }
        },
    
        mouseenter: function () {
            const target = $(this);
            if(!target.hasClass('map-region')) {
                const regionElement = target.siblings('.map-region');
                regionElement.css({
                    'stroke-width': 1,
                    'stroke': '#389efc'
                });
            }
        },
        mouseleave: function () {
            const target = $(this);
            if(!target.hasClass('map-region')) {
                const regionElement = target.siblings('.map-region');
                regionElement.removeAttr('style');
            }
        }
    });
});
